<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row class="top_l" :gutter="10">
					<el-col :span="6" :xs="24"><el-input v-model="userId" clearable placeholder="请输入userId"
							@clear="clearclick"></el-input></el-col>
					<el-col :span="2" :xs="24"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
					style="width: 100%" border stripe>
					<el-table-column prop="userId" label="userId"></el-table-column>
					<el-table-column prop="balance" label="余额"></el-table-column>
					<el-table-column prop="goldBalance" label="金币余额"></el-table-column>
					<el-table-column prop="ingotBalance" label="元宝余额"></el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" width="200" fixed="right" align="center">
						<template v-slot="scope">
							<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
								<el-button v-if="item.parentId == twoId && item.permission.includes('tradeFund')"
									:key="index" size="small" type="primary" plain
									@click="update(item.permission.slice(10), scope.row, item)">
									{{ item.name }}
								</el-button>
							</template>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
						:total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
				<!-- 增加余额 -->
				<el-dialog v-model="dialogAdd" title="增加余额" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" ref="ruleForm" label-width="70px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="增加现金">
									<el-input-number v-model="ruleForm.addBalance" :precision="2" :step="0.1" :min="0"
										placeholder="请输入金额" />

								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="增加金币">
									<el-input-number v-model="ruleForm.addGoldBalance" :min="0" placeholder="请输入增加金币" />
								</el-form-item>
							</el-col>

						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="IncreaseaddFund">提交</el-button>
							<el-button @click="dialogAdd = false">取消</el-button>
						</span>
					</template>
				</el-dialog>

			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'tradeFund',
	data() {
		return {
			dialogAdd: false,
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			userId: '',
			twoId: null,
			ruleForm: {
				addBalance: 0,
				addGoldBalance: 0,
			},
			rowData: {}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
		// 二级目录ID
		this.twoId = this.$route.query.Id;
		this.userId = this.$TOOL.data.get('USERID');
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取账户信息
		this.tradeFundC();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取账户信息
				this.tradeFundC();
			}
		},
		'$store.state.userid'(newval) {
			if (this.userId != newval) {
				this.userId = newval;
				// 获取账户信息
				this.tradeFundC();
			}
		}
	},
	methods: {
		// 获取账户信息
		tradeFundC() {
			this.serloading = true;
			this.$HTTP.post(
				'tradeFund/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					userId: this.userId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			// 获取账户信息
			this.tradeFundC();
		},
		// 权限button按钮方法
		update(methodsNums, row, item) {
			//触发这个参数的方法
			this[methodsNums](row, item);
		},
		// 关联跳转
		push(row, item) {
			this.$store.commit('setuserid', row.userId);
			this.$TOOL.data.set('USERID', row.userId);
			this.$router.push({
				path: item.url.split('?')[0],
				query: {
					Id: item.url.split('=')[1]
				}
			});
		},
		addFund(row) {
			this.dialogAdd = true;
			this.rowData = row;
		},
		IncreaseaddFund() {
			this.$HTTP.post(
				'tradeFund/addFund',
				{
					userId: this.rowData.userId,
					productId: this.productId,
					addBalance: this.ruleForm.addBalance,
					addGoldBalance: this.ruleForm.addGoldBalance,
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.dialogAdd = false
							this.ruleForm.addBalance = 0;
							this.ruleForm.addGoldBalance = 0;

							// 获取账户信息
							this.tradeFundC();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// input 清空
		clearclick() {
			this.$TOOL.data.remove('USERID');
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取账户信息
			this.tradeFundC();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取账户信息
			this.tradeFundC();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.readbox {
	display: flex;
	align-items: center;

	span {
		margin-right: 10px;
		font-size: 16px;
	}


}

.el-input-number {
	width: 100%;
}
</style>
